import { useCallback, useContext, useState } from "react";
import { of, switchMap } from "rxjs";
import { useToggle } from ".";
import { PropertyCategoriesContext, UserContext } from "../components";
import { IVisibleColumn } from "../components/Table/TableHeader";
import { ApplianceCsvReportName } from "../pages/Appliances";
import { AwaitingAllocationCsvReportName } from "../pages/AwaitingAllocation/AwaitingAllocationTab";
import { IssueCsvReportName } from "../pages/Issues/IssuesTable/IssuesTable";
import { ObservationCsvReportName } from "../pages/Observations/ObservationsTable";
import { getFiltersUrlParams } from "../utils/api/getFiltersUrlParams";
import { useFileUrl } from "../utils/api/misc";
import useApiRequest from "../utils/api/useApiRequest";
import { IFilters } from "../utils/api/useFilterable";
import { clearCache } from "../utils/cache";
import { getGlobalUrlParameters, useXTagApiRequest } from "../utils/request";
import { IUrlParameters } from "../utils/url";

const useDownloadCsv = ({
    exportDataUrl,
    filters,
    filterColumns = () => true,
    search,
    showToast,
    getUrl = true,
    csvReportName,
}: IDownloadCsvParams) => {
    const { show, hide, visible } = useToggle();
    const [downloadUrl, setDownloadUrl] = useState("");
    const [downloading, setDownloading] = useState(false);

    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send } = useApiRequest<string>({});
    const sendRequest = useXTagApiRequest();

    const getFileUrl = useFileUrl();
    const handleDownloadClick = useCallback(
        (visibleColumns: IVisibleColumn[]) => {
            setDownloadUrl("");
            if (showToast) {
                showToast();
                clearCache();
            } else {
                show();
            }

            setDownloading(true);

            send(
                sendRequest({
                    url: exportDataUrl,
                    method: "POST",
                    urlParams: {
                        ...getFiltersUrlParams(filters),
                        ...getGlobalUrlParameters(
                            selectedCategories.map((c) => c.id),
                            activeUserParentsIds,
                        ),
                        ...search,
                        ...(csvReportName && {
                            csvReportName: [csvReportName],
                        }),
                    },
                    body: visibleColumns.filter(filterColumns),
                }),
            )
                // TODO: Wait for the user to click the download link before signing the url to make sure that it is not expired.
                .pipe(
                    switchMap((url) => {
                        if (getUrl) {
                            return getFileUrl(url);
                        }
                        return of(null);
                    }),
                )
                .subscribe((url) => {
                    if (url && getUrl) {
                        if (typeof url === "string") {
                            setDownloadUrl(url);
                            setDownloading(false);
                        }
                    }
                });
        },
        [
            showToast,
            send,
            sendRequest,
            exportDataUrl,
            filters,
            selectedCategories,
            activeUserParentsIds,
            search,
            filterColumns,
            show,
            getUrl,
            getFileUrl,
            csvReportName,
        ],
    );

    return { handleDownloadClick, hide, visible, downloadUrl, downloading };
};

export interface IDownloadCsvParams {
    exportDataUrl: string;
    filters: IFilters;
    filterColumns?: (column: IVisibleColumn) => boolean;
    search?: IUrlParameters;
    // TODO: make this non nullable when all the components are updated
    showToast?: () => void;
    // TODO remove this parameter when all the components are updated, this hook should not call the misc api then.
    getUrl?: boolean;
    csvReportName?: CsvReportName;
}

// TODO: If you have a use case where you need to pass the report display name you can add it here as another type after defining it at component level
export type CsvReportName =
    | AwaitingAllocationCsvReportName
    | ApplianceCsvReportName
    | IssueCsvReportName
    | ObservationCsvReportName;

export default useDownloadCsv;
