import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAwaitingAllocationTabProps } from ".";
import { Alert, JobType, Link, Loading, Portal } from "../../../components";
import AllocateJobSimpleModal from "../../../components/AllocateJobSimpleModal";
import DownloadCsvModal from "../../../components/DownloadCsvModal";
import Table, { ITableColumn } from "../../../components/Table";
import Toast from "../../../components/Toast";
import config from "../../../config";
import { useToast, useToggle } from "../../../hooks";
import useDownloadCsv from "../../../hooks/useDownloadCsv";
import useQueryString from "../../../hooks/useQueryString";
import {
    IAwaitingAllocationJob,
    useAwaitingAllocation,
} from "../../../utils/api/allocatedJobs";
import { IFilterToggle } from "../../../utils/api/useFilterable";
import { clearCache } from "../../../utils/cache";
import CancelAwaitingAllocation from "../CancelAwaitingAllocation";
import TableActions from "../TableActions";

const AwaitingAllocationTab = ({
    status,
    csvReportName,
}: IAwaitingAllocationTabProps) => {
    const { t } = useTranslation();

    const jobs = useAwaitingAllocation(status);
    const { clearFilters, toggleFilter, loaded, filters, refresh, search } =
        jobs;

    const { visible, hide, show } = useToggle();
    const {
        visible: cancelModalVisible,
        hide: cancelModalHide,
        show: cancelModalShow,
    } = useToggle();

    const completeCancelModalHide = useCallback(() => {
        cancelModalHide();

        clearCache();
        refresh();
    }, [cancelModalHide, refresh]);

    const [awaitingAllocationJob, setAwaitingAllocationJob] =
        useState<IAwaitingAllocationJob>();

    const propertyCategories = useMemo(
        () =>
            awaitingAllocationJob !== undefined
                ? [
                      {
                          id: awaitingAllocationJob.complianceType.id,
                          displayName:
                              awaitingAllocationJob.complianceType.displayName,
                      },
                  ]
                : [],
        [awaitingAllocationJob],
    );

    const toast = useToast();

    const { getQueryString } = useQueryString(search);
    const {
        downloadUrl,
        hide: csvModalHide,
        downloading,
        handleDownloadClick,
        visible: csvModalVisible,
    } = useDownloadCsv({
        exportDataUrl: `${config.allocatedJobsApiUrl}/awaitingallocation/export`,
        filters,
        filterColumns: (vc) => vc.key !== "actions",
        search: getQueryString(),
        showToast: toast.show,
        getUrl: false,
        csvReportName: csvReportName,
    });

    const columns = useMemo(() => {
        const renderPropertyLink = (
            value: string,
            row: IAwaitingAllocationJob,
        ) => (
            <Link url={`/management/properties/${row.property.id}`}>
                {value}
            </Link>
        );

        const renderJobType = (value: string) => <JobType jobType={value} />;

        const renderActions = (value: unknown, row: IAwaitingAllocationJob) => {
            const openAllocateJobModal = () => {
                setAwaitingAllocationJob(row);
                show();
            };

            const openCancelAwaitingAllocationModal = () => {
                setAwaitingAllocationJob(row);
                cancelModalShow();
            };

            return (
                <TableActions
                    openAllocateJobModal={openAllocateJobModal}
                    openCancelAwaitingAllocationModal={
                        openCancelAwaitingAllocationModal
                    }
                />
            );
        };

        const col: {
            [key: string]: ITableColumn<IAwaitingAllocationJob>;
        } = {
            "property.addressString": {
                title: t("Address"),
                canBeToggledByUser: false,
                render: renderPropertyLink,
            },
            contractorName: {
                title: t("Contractor"),
            },
            description: {
                title: t("Job Description"),
            },
            jobType: {
                title: t("Job Type"),
                render: renderJobType,
            },
            nextService: {
                title: t("Next Service Date"),
                type: "date",
            },
            status: {
                title: t("Status"),
                filterable: status.length > 1,
                getColumnFilters: () => status,
            },
            uta: {
                title: t("Unable to Access"),
                type: "boolean",
                render: (value: boolean) => {
                    return value ? t("Yes") : t("No");
                },
            },
            raisedDate: {
                title: t("Raised Date"),
                type: "date",
            },
            jobPriority: {
                title: t("Job Priority"),
            },
            slaDate: {
                title: t("SLA Date"),
                type: "datetime",
            },
            slaDaysMissedBy: {
                title: t("Days late after SLA"),
                type: "number",
            },
            slaMet: {
                title: t("SLA Met"),
                type: "boolean",
                render: (value: boolean) => {
                    return value ? t("Yes") : t("No");
                },
            },
            requestedDate: {
                title: t("Requested Date"),
                type: "date",
            },
            raisingUser: {
                title: t("Raising User"),
            },
            cancellationReason: {
                title: t("Cancellation Reason"),
                filterable: false,
                sortable: false,
            },
            "cancellingUser.name": {
                title: t("Cancelled By"),
                filterable: false,
                sortable: false,
            },
            actions: {
                title: t("Actions"),
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: renderActions,
            },
        };

        return col;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const handletoggleFilter = useCallback(
        (toggle: IFilterToggle[]) => {
            const newToggle: IFilterToggle[] = [];

            for (const propertyToggle of toggle) {
                if (propertyToggle.property === "status") {
                    const activeFilters = filters["status"].filters.map(
                        (f) => f.value,
                    );

                    for (const filter of propertyToggle.filterGroup.filters) {
                        const index = activeFilters.indexOf(filter.value);
                        if (index > -1) {
                            activeFilters.splice(index, 1);
                        } else {
                            activeFilters.push(filter.value);
                        }
                    }

                    if (activeFilters.length === 0) {
                        newToggle.push({
                            property: "status",
                            appendFilters: false,
                            filterGroup: {
                                operator: "{OR}",
                                filters: status.map((s) => ({
                                    function: "=",
                                    value: s,
                                })),
                            },
                        });
                    } else {
                        newToggle.push(propertyToggle);
                    }
                } else {
                    newToggle.push(propertyToggle);
                }
            }

            toggleFilter(newToggle);
        },
        [filters, status, toggleFilter],
    );

    const handleClearFilters = useCallback(
        (property: string) => {
            if (property === "status") {
                toggleFilter([
                    {
                        property,
                        appendFilters: false,
                        filterGroup: {
                            operator: "{OR}",
                            filters: status.map((s) => ({
                                function: "=",
                                value: s,
                            })),
                        },
                    },
                ]);
            } else {
                clearFilters(property);
            }
        },
        [toggleFilter, clearFilters, status],
    );

    return (
        <>
            {loaded ? (
                <>
                    {csvReportName === "Awaiting allocation received" && (
                        <div style={{ marginBottom: "10px" }}>
                            <Alert type="info">
                                {t(
                                    "You are viewing all awaiting allocation records that are not 'cancelled'.",
                                )}
                            </Alert>
                        </div>
                    )}

                    <Table
                        preferences="awaiting-allocation-jobs-table"
                        columns={columns}
                        {...jobs}
                        clearFilters={handleClearFilters}
                        toggleFilter={handletoggleFilter}
                        alternateCsvFunction={handleDownloadClick}
                        hideChildComponent={toast.visible}
                    />
                </>
            ) : (
                <Loading />
            )}

            {csvModalVisible && (
                <DownloadCsvModal
                    downloadUrl={downloadUrl}
                    downloading={downloading}
                    hide={csvModalHide}
                />
            )}

            {awaitingAllocationJob && visible && (
                <AllocateJobSimpleModal
                    propertyId={awaitingAllocationJob.property.id}
                    addressString={awaitingAllocationJob.property.addressString}
                    propertyCategoryId={awaitingAllocationJob.complianceType.id}
                    propertyCategories={propertyCategories}
                    awaitingAllocationJob={awaitingAllocationJob}
                    hide={hide}
                />
            )}

            {awaitingAllocationJob && cancelModalVisible && (
                <CancelAwaitingAllocation
                    addressString={awaitingAllocationJob.property.addressString}
                    jobId={awaitingAllocationJob.id}
                    hide={completeCancelModalHide}
                />
            )}

            {toast.visible && (
                <Portal>
                    <Toast>
                        {t("Generating report. Check CSV Reports tab.")}
                    </Toast>
                </Portal>
            )}
        </>
    );
};

export default AwaitingAllocationTab;
