import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { IObservationsTableProps } from ".";
import {
    ColourPill,
    Dropdown,
    Icon,
    Link,
    Loading,
    Portal,
} from "../../../components";
import AllocateJobSimpleModal from "../../../components/AllocateJobSimpleModal";
import { ColourPillColour } from "../../../components/ColourPill";
import DownloadCsvModal from "../../../components/DownloadCsvModal";
import Table, { ITableColumn } from "../../../components/Table";
import Toast from "../../../components/Toast";
import config from "../../../config";
import { useToast, useToggle } from "../../../hooks";
import useDownloadCsv from "../../../hooks/useDownloadCsv";
import useQueryString from "../../../hooks/useQueryString";
import {
    IObservation,
    ObservationJobType,
    ObservationStatus,
    useObservations,
} from "../../../utils/api/observations";
import styles from "./ObservationsTable.module.scss";

const ObservationsTable = ({ status }: IObservationsTableProps) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const observations = useObservations({ status });

    const [observationRow, setObservationRow] = useState<IObservation>();

    const { hide, show, visible } = useToggle();

    const toast = useToast();

    const { getQueryString } = useQueryString(observations.search);
    const {
        downloadUrl,
        visible: csvModalVisible,
        handleDownloadClick,
        downloading,
        hide: csvModalHide,
    } = useDownloadCsv({
        exportDataUrl: `${config.issuesApiUrl}/observations/export`,
        filters: observations.filters,
        filterColumns: (vc) => vc.key !== "actions",
        search: getQueryString(),
        showToast: toast.show,
        getUrl: false,
        csvReportName:
            status === "Resolved"
                ? "Observations resolved"
                : "Observations unresolved",
    });

    const columns = useMemo<{
        [key: string]: ITableColumn<IObservation>;
    }>(() => {
        const renderIdLink = (_: unknown, row: IObservation) => (
            <Link url={`/issues/observations/${row.id}`}>#{row.id}</Link>
        );

        const renderSource = (_: unknown, row: IObservation) => (
            <Link url={`/jobs/jobs/${row.job?.id}`}>
                {t("Job")}: #{row.job?.id}
            </Link>
        );

        const renderJobType = (value: ObservationJobType | null) => {
            let colour: ColourPillColour = "lightBlue";

            switch (value) {
                case "Repair": {
                    colour = "orange";
                    break;
                }
                case "Service": {
                    colour = "blue";
                    break;
                }
                case "Install": {
                    colour = "green";
                    break;
                }
                default: {
                    break;
                }
            }

            return value ? (
                <ColourPill colour={colour} value={t(value)} />
            ) : (
                "---"
            );
        };

        const renderPropertyString = (value: string, row: IObservation) => (
            <Link url={`/management/properties/${row.property.id}`}>
                {value}
            </Link>
        );

        const renderDescriptionLink = (value: string, row: IObservation) => (
            <Link url={`/issues/observations/${row.id}`}>{value}</Link>
        );

        const renderStatus = (value: ObservationStatus) => (
            <div className={`${styles.observationStatus} ${styles[value]}`}>
                {t(value)}
            </div>
        );

        const renderAction = (_: unknown, row: IObservation) => {
            const actions = [
                {
                    label: t("View observation"),
                    onClick: () => navigate(`/issues/observations/${row.id}`),
                },
                ...(status === "Unresolved"
                    ? [
                          {
                              label: t("Allocate job"),
                              onClick: () => {
                                  setObservationRow(row);
                                  show();
                              },
                          },
                      ]
                    : []),
            ];

            return (
                <Dropdown
                    label={<Icon icon="more" ariaLabel={t("Actions")} />}
                    size="small"
                    items={actions}
                />
            );
        };

        return {
            id: {
                title: "Id",
                render: renderIdLink,
                filterable: false,
            },
            dateRaised: {
                title: "Date",
                type: "datetime",
            },
            "job.id": {
                title: "Source",
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: renderSource,
            },
            "job.jobType": {
                title: t("Job type"),
                render: renderJobType,
            },
            "fuelType.displayName": {
                title: t("Fuel type"),
                render: (value: string | null) => value || "----",
            },
            "property.address": {
                title: t("Address"),
                filterable: false,
                render: renderPropertyString,
            },
            "landlord.name": {
                title: t("Landlord"),
            },
            "type.displayName": {
                title: t("Observation code"),
            },
            description: {
                title: t("Observation description"),
                render: renderDescriptionLink,
            },
            "status.displayName": {
                title: t("Status"),
                render: renderStatus,
            },
            lastEdited: {
                title: t("Last edited"),
                type: "datetime",
            },
            actions: {
                title: t("Actions"),
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: renderAction,
            },
        };
    }, [navigate, show, status, t]);

    return observations.loaded ? (
        <>
            <Table
                preferences="observations-table"
                columns={columns}
                {...observations}
                alternateCsvFunction={handleDownloadClick}
                hideChildComponent={toast.visible}
            />
            {observationRow && visible && (
                <AllocateJobSimpleModal
                    propertyId={observationRow.property.id}
                    addressString={observationRow.property.address}
                    hide={hide}
                    propertyCategories={[observationRow.fuelType]}
                    propertyCategoryId={observationRow.fuelType.id}
                />
            )}

            {csvModalVisible && (
                <DownloadCsvModal
                    downloadUrl={downloadUrl}
                    downloading={downloading}
                    hide={csvModalHide}
                />
            )}

            {toast.visible && (
                <Portal>
                    <Toast>
                        {t("Generating report. Check CSV Reports tab.")}
                    </Toast>
                </Portal>
            )}
        </>
    ) : (
        <Loading />
    );
};

export default ObservationsTable;
