import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ApplianceCsvReportName } from ".";
import { Card, Link, Loading, Portal, Table } from "../../components";
import { ITableColumn } from "../../components/Table";
import Toast from "../../components/Toast";
import config from "../../config";
import { useToast } from "../../hooks";
import useDownloadCsv from "../../hooks/useDownloadCsv";
import useQueryString from "../../hooks/useQueryString";
import {
    IApplianceCategory,
    ISimpleAppliance,
    useAppliances,
} from "../../utils/api/appliances";

const Appliances = () => {
    const { t } = useTranslation();
    const appliances = useAppliances({
        sortProperty: "make",
        sortDirection: "asc",
    });

    const { getQueryString } = useQueryString(appliances.search);

    const applianceCsvReportName: ApplianceCsvReportName = "Appliances";

    const toast = useToast();

    const { handleDownloadClick } = useDownloadCsv({
        exportDataUrl: `${config.appliancesApiUrl}/export`,
        filters: appliances.filters,
        filterColumns: (vc) => vc.key !== "id",
        search: getQueryString(),
        showToast: toast.show,
        csvReportName: applianceCsvReportName,
    });

    const columns = useMemo(() => {
        const renderAddressString = (value: string, row: ISimpleAppliance) => (
            <Link url={`/management/properties/${row.property.id}`}>
                {value}
            </Link>
        );

        const renderId = (value: string) => (
            <Link url={`/management/appliances/${value}`}>
                {t("View Appliance")}
            </Link>
        );

        const col: { [key: string]: ITableColumn<ISimpleAppliance> } = {
            "type.categories": {
                title: t("Categories"),
                render: (value: IApplianceCategory[]) =>
                    value.map((v) => v.displayName).join(", "),
            },
            fuelType: { title: t("Fuel Type") },
            "type.displayName": { title: t("Type") },
            make: { title: t("Make"), canBeToggledByUser: false },
            model: { title: t("Model"), canBeToggledByUser: false },
            safeToUseV2: {
                title: t("Safety Status"),
                render: (value: unknown, row) =>
                    row.safeToUse ? t("Safe") : t("Unsafe"),
                type: "boolean",
                getBooleanFilters: () => [
                    { displayText: t("Safe"), value: "true" },
                    { displayText: t("Unsafe"), value: "false" },
                ],
            },
            location: { title: t("Location") },
            currentAttemptsCount: { title: t("UTA Attempts"), type: "number" },
            lastAttemptDate: { title: t("Latest Attempt"), type: "date" },
            conditionOfAppliance: { title: t("Condition") },
            flueAngle: { title: t("Flue Angle") },
            installDate: { title: t("Install Date"), type: "date" },
            expiryDate: { title: t("Expiry Date"), type: "date" },
            sealReplacementDate: {
                title: t("Seal Replacement Date"),
                type: "date",
            },
            isInstalled: {
                title: t("Installed"),
                render: (value: boolean) => (value ? t("Yes") : t("No")),
                filterable: true,
                type: "boolean",
            },
            nextServiceDate: { title: t("Next Service Date"), type: "date" },
            "property.addressString": {
                title: t("Property"),
                filterable: false,
                render: renderAddressString,
            },
            landlordsApplianceV2: {
                title: t("Appliance owner"),
                render: (value: unknown, row) =>
                    row.landlordsAppliance ? t("Landlord") : t("Tenant"),
                type: "boolean",
                getBooleanFilters: () => [
                    { displayText: t("Landlord"), value: "true" },
                    { displayText: t("Tenant"), value: "false" },
                ],
            },
            "property.uprn": { title: t("Uprn"), filterable: false },
            ageOfAppliance: { title: t("Age"), type: "number" },
            isCapped: {
                title: t("Capped"),
                render: (value: boolean) => (value ? t("Yes") : t("No")),
                filterable: true,
                type: "boolean",
            },
            isTestPassed: {
                title: t("Test Outcome"),
                render: (value: boolean | null) => {
                    if (value === null) {
                        return;
                    }
                    return value ? t("Pass") : t("Fail");
                },
                type: "boolean",
            },
            cappedDate: { title: t("Capped Date"), type: "date" },
            id: {
                title: t("View"),
                filterable: false,
                sortable: false,
                render: renderId,
            },
        };

        return col;
    }, [t]);

    return (
        <>
            <Card title={t("Appliances")}>
                {appliances.loaded ? (
                    <Table
                        preferences="appliances-table"
                        columns={columns}
                        {...appliances}
                        alternateCsvFunction={handleDownloadClick}
                        hideChildComponent={toast.visible}
                    />
                ) : (
                    <Loading />
                )}
            </Card>

            {toast.visible && (
                <Portal>
                    <Toast>
                        {t("Generating report. Check CSV Reports tab.")}
                    </Toast>
                </Portal>
            )}
        </>
    );
};

export default Appliances;
