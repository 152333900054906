import AwaitingAllocationTab from "./AwaitingAllocationTab";

export type AwaitingAllocationStatus =
    | "Allocated"
    | "Awaiting Allocation"
    | "Awaiting Re-allocation"
    | "Completed"
    | "Cancelled";

export type AwaitingAllocationCsvReportName =
    | "Awaiting allocation open"
    | "Awaiting allocation completed"
    | "Awaiting allocation cancelled"
    | "Awaiting allocation received";

export interface IAwaitingAllocationTabProps {
    status: AwaitingAllocationStatus[];
    csvReportName: AwaitingAllocationCsvReportName;
}

export default AwaitingAllocationTab;
