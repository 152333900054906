import { ObservationStatus } from "../../../utils/api/observations";
import ObservationsTable from "./ObservationsTable";

export interface IObservationsTableProps {
    status: ObservationStatus;
}

export type ObservationCsvReportName =
    | "Observations resolved"
    | "Observations unresolved";

export default ObservationsTable;
