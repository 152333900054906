import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, Link, Loading, Portal, Table } from "../../components";
import DownloadCsvModal from "../../components/DownloadCsvModal";
import { IBooleanFilter, ITableColumn } from "../../components/Table";
import Toast from "../../components/Toast";
import config from "../../config";
import { useToast } from "../../hooks";
import useDownloadCsv from "../../hooks/useDownloadCsv";
import useQueryString from "../../hooks/useQueryString";
import { ICircuit, useCircuits } from "../../utils/api/assets";

const Circuits = () => {
    const { t } = useTranslation();
    const circuits = useCircuits();
    const { getQueryString } = useQueryString(circuits.search);

    const toast = useToast();

    const { downloadUrl, visible, handleDownloadClick, downloading, hide } =
        useDownloadCsv({
            exportDataUrl: `${config.assetsApiUrl}/circuits/export`,
            filters: circuits.filters,
            filterColumns: (vc) =>
                vc.key !== "id" && vc.key !== "mainSwitchSeparator",
            search: getQueryString(),
            showToast: toast.show,
            getUrl: false,
        });

    const columns = useMemo(() => {
        const renderAddress = (value: string, row: ICircuit) => (
            <Link url={`/management/properties/${row.property.id}`}>
                {value}
            </Link>
        );

        const renderCircuitLink = (value: string, row: ICircuit) => (
            <Link url={`/management/circuits/${row.id}`}>{value}</Link>
        );

        const renderIsRcd = (value: boolean) => (value ? t("Yes") : t("No"));

        const renderPhaseType = (value: boolean) =>
            value ? t("Three phase") : t("Single phase");

        const getPhaseTypeBooleanFilters = (): IBooleanFilter[] => [
            { value: "true", displayText: t("Three phase") },
            { value: "false", displayText: t("Single phase") },
        ];

        const col: { [key: string]: ITableColumn<ICircuit> } = {
            "property.address": {
                title: t("Property"),
                filterable: false,
                render: renderAddress,
            },
            "board.name": { title: t("Board name") },
            "board.location": { title: t("Board location") },
            number: { title: t("Circuit number") },
            description: {
                title: t("Circuit description"),
                render: renderCircuitLink,
            },
            bsEn: { title: t("BS (EN)") },
            "type.displayName": { title: t("Type") },
            threePhase: {
                title: t("Phase type"),
                type: "boolean",
                render: renderPhaseType,
                getBooleanFilters: getPhaseTypeBooleanFilters,
            },
            rating: { title: t("Rating") },
            breakingCapacity: { title: t("Breaking Capacity (kA)") },
            "protectiveCircuit.operatingCurrent": {
                title: t("RCD operating current (mA)"),
            },
            maximumZs: { title: t(`Max Zs (\u2126)`) },
            rampTest: { title: t("Ramp test (mA)") },
            referenceMethod: { title: t("Reference method") },
            live: { title: t("Live mm\u00B2") },
            cpc: { title: t("cpc mm\u00B2") },
            mainSwitchSeparator: {
                title: t("Main switch information"),
                filterable: false,
                sortable: false,
                separator: true,
            },
            "protectiveCircuit.description": { title: t("Protected by RCD") },
            "board.mainSwitch.location": { title: t("Location") },
            "board.mainSwitch.bsEn": { title: t("BS (EN)") },
            "board.mainSwitch.numberOfPoles": {
                title: t("Number of poles"),
                type: "number",
            },
            "board.mainSwitch.currentRating": {
                title: t("Current rating (A)"),
            },
            "board.mainSwitch.fuseRating": { title: t("Fuse rating (A)") },
            "board.mainSwitch.voltageRating": {
                title: t("Voltage rating (V)"),
            },
            "board.mainSwitch.isRcd": {
                title: t("RCD used as Main Switch"),
                type: "boolean",
                render: renderIsRcd,
            },
            "board.mainSwitch.ratedResidualOperatingCurrent": {
                title: t("Rated residual operation current (mA)"),
            },
            "board.mainSwitch.ratedTimeDelay": {
                title: t("Rated time delay (ms)"),
            },
            "board.mainSwitch.measuredOperatingTime": {
                title: t("Measured operating time (ms)"),
            },
        };

        return col;
    }, [t]);

    return (
        <>
            <Card title={t("Circuits")}>
                {circuits.loaded ? (
                    <Table
                        preferences="circuits-table"
                        columns={columns}
                        {...circuits}
                        alternateCsvFunction={handleDownloadClick}
                        hideChildComponent={toast.visible}
                    />
                ) : (
                    <Loading />
                )}
            </Card>

            {visible && (
                <DownloadCsvModal
                    downloadUrl={downloadUrl}
                    downloading={downloading}
                    hide={hide}
                />
            )}

            {toast.visible && (
                <Portal>
                    <Toast>
                        {t("Generating report. Check CSV Reports tab.")}
                    </Toast>
                </Portal>
            )}
        </>
    );
};

export default Circuits;
