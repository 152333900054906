import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    ColourPill,
    Link,
    Loading,
    Modal,
    Portal,
    Table,
} from "../../../components";
import AllocateJobSimpleModal from "../../../components/AllocateJobSimpleModal";
import { ColourPillColour } from "../../../components/ColourPill";
import DownloadCsvModal from "../../../components/DownloadCsvModal";
import ModalBody from "../../../components/Modal/ModalBody";
import { IBulkAction, ITableColumn } from "../../../components/Table";
import Toast from "../../../components/Toast";
import config from "../../../config";
import { useToast, useToggle } from "../../../hooks";
import useDownloadCsv from "../../../hooks/useDownloadCsv";
import useQueryString from "../../../hooks/useQueryString";
import {
    IFuelType,
    ISimpleIssue,
    useAssignIssue,
    useIssues,
} from "../../../utils/api/issues";
import { IUser } from "../../../utils/api/users";
import AssignIssue from "../AssignIssue/AssignIssue";
import TableActions from "../TableActions";
import styles from "./IssuesTable.module.scss";

const IssuesTable = ({ status = "Inbox", users }: IIssueTableProps) => {
    const issues = useIssues(status);
    const assignIssue = useAssignIssue();

    const { t } = useTranslation();
    const {
        show: assignModalShow,
        hide: assignModalHide,
        visible: assignModalVisible,
    } = useToggle();
    const {
        hide: allocateJobModalHide,
        show: allocateJobModalShow,
        visible: allocateJobModalVisible,
    } = useToggle();

    const [selectedIssueIds, setSelectedIssueIds] = useState<number[]>([]);

    const [selectedPropertyId, setSelectedProperty] = useState<number | null>(
        null,
    );

    const [selectedComplianceType, setSelectedComplianceType] = useState<
        IFuelType | undefined
    >();

    const toast = useToast();

    const csvReportNameStatusMap: {
        [status: string]: IssueCsvReportName;
    } = {
        Inbox: "Issues my",
        Unresolved: "Issues unresolved",
        Resolved: "Issues resolved",
        Blocked: "Issues blocked",
    };

    const { getQueryString } = useQueryString(issues.search);
    const { downloadUrl, hide, downloading, handleDownloadClick, visible } =
        useDownloadCsv({
            exportDataUrl: `${config.issuesApiUrl}/export`,
            filters: issues.filters,
            filterColumns: (vc) => vc.key !== "actions",
            search: getQueryString(),
            showToast: toast.show,
            getUrl: false,
            csvReportName: csvReportNameStatusMap[status],
        });

    const [selectedPropertyAddressString, setSelectedPropertyAddressString] =
        useState<string | null>(null);

    const openAssignIssueModal = (issueIds: number) => {
        setSelectedIssueIds([issueIds]);
        assignModalShow();
    };

    const openBulkAssignIssuesModal = (ids: number[]) => {
        setSelectedIssueIds(ids);
        assignModalShow();
    };

    const onSave = (userId: string) => {
        assignIssue
            .assignIssue(Number(userId), selectedIssueIds)
            .subscribe(assignModalHide);
    };

    const columns = useMemo(() => {
        const renderActions = (value: string, row: ISimpleIssue) => {
            const openAllocateJobModal = () => {
                setSelectedProperty(row.property.id);
                setSelectedPropertyAddressString(row.property.addressString);
                setSelectedComplianceType(row.fuelType);
                allocateJobModalShow();
            };

            const assignModalOpen = () => {
                openAssignIssueModal(row.id);
            };

            return (
                <TableActions
                    issueId={row.id}
                    openAllocateJobModal={openAllocateJobModal}
                    assignIssueModalOpen={assignModalOpen}
                />
            );
        };

        const renderSource = (value: unknown, row: ISimpleIssue) => {
            if (row.job) {
                return (
                    <Link url={`/jobs/jobs/${row.job.id}`}>
                        {t("Job")}: #{row.job.id}
                    </Link>
                );
            }

            return (
                <Link url={`/management/properties/${row.property.id}`}>
                    {t("Property")}: #{row.property.id}
                </Link>
            );
        };

        const renderJobType = (value: string | null) => {
            let colour: ColourPillColour = "lightBlue";

            switch (value) {
                case "Repair": {
                    colour = "orange";
                    break;
                }
                case "Service": {
                    colour = "blue";
                    break;
                }
                case "Install": {
                    colour = "green";
                    break;
                }
                default: {
                    break;
                }
            }

            return value ? (
                <ColourPill colour={colour} value={t(value)} />
            ) : (
                "---"
            );
        };

        const renderTitle = (value: string, row: ISimpleIssue) => {
            return (
                <Link url={`/issues/${row.id}`}>
                    <div title={row.description}>{value}</div>
                </Link>
            );
        };

        const renderStatus = (value: string, row: ISimpleIssue) => {
            return (
                <div
                    className={`${styles.issueStatus} ${
                        value === "Blocked"
                            ? styles.blocked
                            : value === "Resolved"
                            ? styles.resolved
                            : value === "Unresolved"
                            ? styles.unresolved
                            : ""
                    }`}
                >
                    {t(value)}
                </div>
            );
        };

        const col: { [key: string]: ITableColumn<ISimpleIssue> } = {
            createdAt: {
                title: "Date",
                type: "datetime",
            },
            source: {
                title: "Source",
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: renderSource,
            },
            "job.jobType": {
                title: t("Job Type"),
                render: renderJobType,
            },
            "fuelType.displayName": {
                title: t("Fuel Type"),
                render: (value: string | null) => {
                    return value || "----";
                },
            },
            "property.addressString": {
                title: t("Address"),
                filterable: false,
                render: (value: string, row) => {
                    if (row.property) {
                        return (
                            <Link
                                url={`/management/properties/${row.property.id}`}
                            >
                                {value}
                            </Link>
                        );
                    } else {
                        return "----";
                    }
                },
            },
            "property.uprn": {
                title: t("UPRN"),
                filterable: false,
            },
            "job.engineer.name": {
                title: t("Engineer"),
                render: (value: string, row) => {
                    if (value) {
                        return (
                            <Link
                                url={`/management/engineers/${row.job?.engineer.id}`}
                            >
                                {value}
                            </Link>
                        );
                    } else {
                        return t("N/A");
                    }
                },
            },
            "landlord.name": {
                title: t("Landlord"),
            },
            title: {
                title: t("Issue"),
                render: renderTitle,
            },
            status: {
                title: t("Status"),
                render: renderStatus,
            },
            "assignedUser.name": {
                title: t("Assigned User"),
                render: (value: string | null) => {
                    return value || "----";
                },
            },
            updatedAt: {
                title: t("Last Edited"),
                type: "datetime",
            },
            actions: {
                title: t("Actions"),
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: renderActions,
            },
        };

        return col;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const bulkActions = useMemo<IBulkAction[]>(
        () => [
            {
                value: "assignIssues",
                label: t("Assign Issues"),
                onSubmit: openBulkAssignIssuesModal,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [issues, t],
    );

    return issues.loaded ? (
        <>
            <Table
                preferences="issues-table"
                columns={columns}
                bulkActions={bulkActions}
                {...issues}
                alternateCsvFunction={handleDownloadClick}
                hideChildComponent={toast.visible}
            />
            {selectedPropertyId &&
                selectedPropertyAddressString &&
                selectedComplianceType &&
                allocateJobModalVisible && (
                    <AllocateJobSimpleModal
                        propertyId={selectedPropertyId}
                        addressString={selectedPropertyAddressString}
                        propertyCategoryId={selectedComplianceType.id}
                        propertyCategories={[
                            {
                                id: selectedComplianceType.id,
                                displayName: selectedComplianceType.displayName,
                            },
                        ]}
                        hide={allocateJobModalHide}
                    />
                )}

            {assignModalVisible && (
                <Portal>
                    <Modal title={t("Assign Issue")} hide={assignModalHide}>
                        <ModalBody>
                            <AssignIssue
                                users={users}
                                onSave={onSave}
                                loading={assignIssue.loading}
                            />
                        </ModalBody>
                    </Modal>
                </Portal>
            )}

            {visible && (
                <DownloadCsvModal
                    downloadUrl={downloadUrl}
                    downloading={downloading}
                    hide={hide}
                />
            )}

            {toast.visible && (
                <Portal>
                    <Toast>
                        {t("Generating report. Check CSV Reports tab.")}
                    </Toast>
                </Portal>
            )}
        </>
    ) : (
        <Loading />
    );
};

interface IIssueTableProps {
    status: "Inbox" | "Blocked" | "Unresolved" | "Resolved";
    users: IUser[];
}

export type IssueCsvReportName =
    | "Issues my"
    | "Issues unresolved"
    | "Issues resolved"
    | "Issues blocked";

export default IssuesTable;
